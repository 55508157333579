.lucky-hours-section {
  &__empty-state-img {
    width: 100px;
    @include to-tablet-sm {
      width: 60px;
    }
  }
  &__item {
    &__offer-badge {
      background-color: $color4;
      top: 15%;
      width: 70px;
      height: 30px;
      @include from-tablet-lg {
        width: 120px;
        height: 38px;
      }
      &::after,
      &::before {
        content: "";
        position: absolute;
        right: 0;
        border-style: solid;
        border-width: 8px;
        @include from-tablet-lg {
          border-width: 10px;
        }
        transform: translateX(99%);
      }
      &::after {
        top: 0;
        border-color: $color4 transparent transparent $color4;
      }
      &::before {
        bottom: 0;
        border-color: transparent transparent $color4 $color4;
      }
    }
  }
}
