/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.dark-navbar {
  background-color: rgba(38, 45, 56, 0.48);
}
.dark-navbar-scrolled {
  background-color: #151d41;
}
.white-navbar {
  background-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.mct-navbar {
  z-index: 1000 !important;
  width: 100%;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  .navbar-toggler,
  .navbar-toggler:focus-visible {
    border: none !important;
    outline: 0 !important;
  }
  &__logo-figure {
    width: 150px;
    height: 75px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  &__logoIcon {
    width: 100%;
    height: 100%;
  }
  &__menu-links {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  &__menu {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 8.125rem;
  }
  &__page {
    font-size: 14px;
    font-weight: 500;
  }
  .page-dark {
    color: white;
  }
  .page-light {
    color: #000000;
  }
  &__page:hover {
    color: #a7c0e8;
  }
}

@include to-desktop-sm {
  .mct-navbar {
    &__menu {
      padding-inline: 3.125rem;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
    }
  }
}
@include to-tablet-lg {
  .mct-navbar {
    &__menu-links {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    &__menu {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding-inline: 0;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: start;
    }
    &__page {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      display: inline-block;
      margin-block: 0.3125rem;
      font-size: 18px;
    }
  }
}
@include to-mobile-sm {
  .mct-navbar {
    &__page {
      font-size: 16px;
    }
  }
}
.navbar-toggler,
.navbar-toggler:focus {
  outline: none !important;
}