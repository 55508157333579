/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */

body {
  background-color: $color1 !important;
  font-family: "Montserrat", "Roboto", sans-serif;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-break: break-word;
}
ul,
li {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* -------------------------------------------------------------------------- */
/*                                   placeholder                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                   colors                                   */
/* -------------------------------------------------------------------------- */

.green-text {
  color: $color4;
}
.white-text {
  color: $color6-text;
}
.main-dark-text {
  color: $color1;
}
.text--blue-2 {
  color: $exchange-label;
}
.text--secondary-4 {
  color: $secondary-4;
}
.text--secondary-8 {
  color: $secondary-8;
}
.text--natural-black {
  color: $natural--black;
}
.gray--8 {
  color: $gray-8;
}
.dark-gray {
  color: $color13;
}
.bg--main-blue {
  background-color: $color1;
}
.w-fit {
  width: fit-content;
}
/* -------------------------------------------------------------------------- */
/*                                    fonts                                   */
/* -------------------------------------------------------------------------- */
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.cairo-family {
  font-family: "Cairo", sans-serif !important;
}

@include to-tablet-sm {
  .fsize-1 {
    font-size: 2rem;
  }
  .fsize-2 {
    font-size: 1.5rem;
  }
  .fsize-3 {
    font-size: 1rem;
  }
  .fsize-4 {
    font-size: 1.25rem;
  }
  .fsize-5 {
    font-size: 1rem;
  }
  .fsize-6 {
    font-size: 1rem;
  }
  .fsize-7 {
    font-size: 0.75rem;
  }
  .fsize-8 {
    font-size: 1.25rem;
  }
  .fsize-9 {
    font-size: 1rem;
  }
  .fsize-10 {
    font-size: 0.6rem;
  }
  .fsize-11 {
    font-size: 1rem;
  }
  .fsize-12 {
    font-size: 0.95rem;
  }
  .fsize-13 {
    font-size: 1.75rem;
  }
  .fsize-14 {
    font-size: 1.75rem;
  }
}

@include from-tablet-sm {
  .fsize-1 {
    font-size: 3.5rem;
  }
  .fsize-2 {
    font-size: 3.5rem;
  }
  .fsize-3 {
    font-size: 1.75rem;
  }
  .fsize-4 {
    font-size: 1.75rem;
  }
  .fsize-5 {
    font-size: 1.35rem;
  }
  .fsize-6 {
    font-size: 1.25rem;
  }
  .fsize-7 {
    font-size: 1.25rem;
  }
  .fsize-8 {
    font-size: 1.25rem;
  }
  .fsize-9 {
    font-size: 1rem;
  }
  .fsize-10 {
    font-size: 0.75rem;
  }
  .fsize-11 {
    font-size: 1rem;
  }
  .fsize-12 {
    font-size: 1rem;
  }
  .fsize-13 {
    font-size: 2.5rem;
  }
  .fsize-14 {
    font-size: 1.25rem;
  }
}

@include from-desktop-lg {
  .fsize-1 {
    font-size: 4rem;
  }
  .fsize-2 {
    font-size: 5.25rem;
  }
  .fsize-3 {
    font-size: 3.25rem;
  }
  .fsize-4 {
    font-size: 3.25rem;
  }
  .fsize-5 {
    font-size: 2rem;
  }
  .fsize-6 {
    font-size: 1.875rem;
  }
  .fsize-7 {
    font-size: 2.18rem;
  }
  .fsize-8 {
    font-size: 2rem;
  }
  .fsize-9 {
    font-size: 1.5rem;
  }
  .fsize-10 {
    font-size: 0.75rem;
  }
  .fsize-11 {
    font-size: 1.25rem;
  }
  .fsize-12 {
    font-size: 1rem;
  }
  .fsize-13 {
    font-size: 2.5rem;
  }
  .fsize-14 {
    font-size: 1.75rem;
  }
}

@include from-larger-screens {
  .fsize-1 {
    font-size: 7.4rem;
  }
  .fsize-2 {
    font-size: 6.3rem;
  }
  .fsize-3 {
    font-size: 5.3rem;
  }
  .fsize-4 {
    font-size: 3.9rem;
  }
  .fsize-5 {
    font-size: 3rem;
  }
  .fsize-6 {
    font-size: 2.25rem;
  }
  .fsize-7 {
    font-size: 2.18rem;
  }
  .fsize-8 {
    font-size: 2rem;
  }
  .fsize-9 {
    font-size: 1.5rem;
  }
  .fsize-10 {
    font-size: 0.9375rem;
  }
  .fsize-11 {
    font-size: 2rem;
  }
  .fsize-12 {
    font-size: 1.25rem;
  }
  .fsize-13 {
    font-size: 3rem;
  }
  .fsize-14 {
    font-size: 2.5rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                                   buttons                                  */
/* -------------------------------------------------------------------------- */
// Language button
.language-btn {
  &__nav-titles {
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }
  &__nav-icon {
    -webkit-transition: fill 0.3s ease;
    -o-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  &:hover .recycle-nav__nav-icon {
    fill: $color4;
  }
  &:hover .recycle-nav__nav-titles {
    color: $color4;
  }
}

/* -------------------------------------------------------------------------- */
/*                                    texts                                   */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                   drawers                                  */
/* -------------------------------------------------------------------------- */
.main-drawer {
  &--ar {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-close {
        margin-inline-end: 0;
        margin-inline-start: 12px;
      }
    }
  }
  .ant-drawer-body {
    background-color: $color1;
    color: white;
  }
  &__close-icon {
    width: 24px;
  }
}
/* -------------------------------------------------------------------------- */
/*                                global setup                                */
/* -------------------------------------------------------------------------- */
input:autofill,
input.ant-input:focus,
.ant-input-affix-wrapper > input.ant-input:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -moz-box-shadow: 0 0 0 30px #fff inset !important;
  -o-box-shadow: 0 0 0 30px #fff inset !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}

.ant-select-single.ant-select-lg .ant-select-selector,
.ant-input-lg {
  border-radius: 35px;
}

.ant-btn.ant-btn-lg {
  height: auto;
}

@include to-tablet-sm {
  .ant-form-item .ant-form-item-label > label {
    font-size: 0.75rem;
  }

  .ant-modal .ant-modal-content {
    border-radius: 24px;
    padding-inline: 12px;
    padding-top: 40px;
  }

  .ant-btn.ant-btn-lg {
    font-size: 1rem;
    height: auto;
    width: 236px;
    border-radius: 31px;
  }

  .ant-modal .ant-modal-close {
    top: 12px;
    left: 20px !important;
  }
}

@include from-tablet-sm {
  .ant-modal .ant-modal-content {
    border-radius: 24px;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .ant-modal .ant-modal-close {
    top: 13px;
    left: 20px !important;
  }
}

@include from-desktop-lg {
  .ant-form-item .ant-form-item-label > label {
    font-size: 1.18rem;
  }

  .ant-modal .ant-modal-content {
    border-radius: 33px;
    padding: 52px 34px 34px;
  }

  .ant-btn.ant-btn-lg {
    font-size: 1.625rem;
    width: 367px;
  }

  .ant-modal .ant-modal-close {
    top: 20px;
    left: 33px !important;
  }
}

@include from-larger-screens {
  .ant-form-item .ant-form-item-label > label {
    font-size: 2.18rem;
  }

  .ant-modal .ant-modal-content {
    border-radius: 50px;
    padding: 71px 60px;
  }

  .ant-btn.ant-btn-lg {
    font-size: 2rem;
    width: 437px;
  }

  .ant-modal .ant-modal-close {
    top: 35px;
    left: 45px !important;
  }
}

// modal accordion
#balance .accordion-item,
#balance .accordion-item:last-of-type {
  border-radius: 0.375rem !important;
  overflow: hidden;
}

#balance .accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6 !important;
}

#balance .accordion-button::after {
  position: absolute;
  right: 1rem;
  top: 23px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.right-direction .accordion-button::after {
  left: 1rem;
  right: unset !important;
}

#balance .accordion-button {
  background-color: #262b41 !important;
  color: white !important;
}

#balance .accordion-body {
  background-color: #262b41 !important;
}

#balance .accordion-button:not(.collapsed)::after {
  -webkit-transform: translateY(-50%) rotate(180deg) !important;
  -ms-transform: translateY(-50%) rotate(180deg) !important;
  transform: translateY(-50%) rotate(180deg) !important;
}

#balance .accordion-button::after,
#balance .accordion-button:not(.collapsed)::after {
  background-image: url("../../icons/whiteArrowDown.svg") !important;
}

#balance .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.text-underline {
  text-decoration: underline;
}

.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: auto;
}

// paymob iframe
iframe {
  height: 820px;
}

@include from-tablet-sm {
  iframe {
    width: 600px;
  }
}

@include to-tablet-sm {
  iframe {
    width: 100%;
  }
}
