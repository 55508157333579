/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.mct-section-whatwedo {
  background-color: #eff1f2;
}
.what-we-do {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-block: 20px;
  &__card-p {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #262d38;
    padding-inline: 10px;
  }
  &__cards {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-margin-after: 20px;
            margin-block-end: 20px;
  }

  &__card {
    width: 409px;
    height: 585px;
    z-index: 3;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  &__card:hover &__card-box {
    height: 100%;
    border-radius: 10px;
  }
  &__card-const:hover &__card-box {
    height: 100%;
    border-radius: 10px;
  }
  &__card-img {
    width: 100%;
    height: 100%;
  }
  &__card-const {
    width: 885px;
    height: 19.4375rem;
    z-index: 3;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  &__card-box {
    position: absolute;
    width: 100%;
    height: 7.6875rem;
    background-color: #262d38;
    opacity: 0.74;
    z-index: 344;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &:hover {
      height: 100%;
      border-radius: 10px;
    }
  }
  &__card-box-title {
    font-size: 31.25px;
    font-weight: 600;
    color: white;
    text-align: center;
  }
  &__card-box-p {
    font-size: 20px;
    font-weight: 600;
    color: white;
    text-align: left;
  }
}
@include to-tablet-lg {
  .what-we-do {
    &__cards {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    &__card-const {
      width: 100%;
    }
    &__card {
      -webkit-margin-after: 20px;
              margin-block-end: 20px;
    }
    &__card-box-title {
      font-size: 25px;
    }
  }
}

@include to-mobile-sm {
  .what-we-do {
    &__card-p {
      font-size: 16px;
    }
    &__card {
      width: 90%;
    }
    &__card-const {
      width: 90%;
    }
    &__card-box-title {
      font-size: 25px;
    }
    &__card-box-p {
      font-size: 16px;
    }
  }
}