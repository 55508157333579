.bidders {
  padding: 32px 20px;
  @include from-desktop-sm {
    padding: 64px;
  }
  &__filters-radio-holder {
    &__radio-btn {
      .ant-radio-inner {
        border-color: $color4 !important;
        background-color: $color1 !important;
      }
      .ant-radio-checked {
        .ant-radio-inner {
          &::after {
            content: none;
          }
          border-width: 2px;
          border-color: #031830 !important;
          background-color: #0db14b !important;
          outline: 1px solid #0db14b;
        }
      }
    }
  }
  &__search-holder {
    max-width: 800px;
    .ant-input-affix-wrapper {
      border-color: $color4 !important;
      border-width: 1px !important;
    }
    input {
      &::placeholder {
        color: $gray-6;
        font-weight: 700;
      }
    }
    .ant-input-prefix {
      margin-inline-end: 8px;
      img {
        width: 18px;
      }
    }
  }
  &__back-to-auctions-btn {
    img {
      @include to-tablet-sm {
        width: 30px;
      }
      @include from-tablet-sm {
        width: 50px;
      }
      @include from-desktop-lg {
        width: 80px;
      }
    }
    &--ar {
      img {
        transform: rotateY(180deg);
      }
    }
  }
  &__auctions-holder {
    row-gap: 32px;
    > div {
      @include to-tablet-sm {
        display: flex;
        justify-content: center;
      }
    }
  }
  &__auction {
    background-color: white;
    padding: 32px 12px;
    @include to-tablet-sm {
      width: 100%;
    }
    @include from-desktop-sm {
      padding: 32px;
    }
    border-radius: 24px;
    &__max-length {
      max-width: 25ch;
      margin-left: auto;
      margin-right: auto;
    }
    &__start-date-icon {
      @include to-tablet-sm {
        width: 18px;
      }
      @include from-tablet-sm {
        width: 24px;
      }
      @include from-desktop-sm {
        width: 32px;
      }
    }
    &__minBiddingValue {
      color: $secondary-8;
      padding: 8px 12px;
      background-color: $primary-6;
      border-radius: 100px;
    }
    &__info-list {
      width: fit-content;
      li {
        list-style: disc !important;
        color: $color13;
        font-weight: bold;
      }
      &__end-time {
        padding: 4px 16px;
        border-radius: 25px;
        background-color: $color12;
      }
    }
    &__join-btn {
      background-color: $color4;
      &:disabled {
        background-color: $gray-8;
      }
      padding: 8px 16px;
      border-radius: 16px;
      width: 120px;
      @include from-tablet-sm {
        width: 150px;
      }
      @include from-desktop-lg {
        width: 206px;
      }
    }
  }
  &__empty-state {
    &__no-result-icon {
      @include to-tablet-sm {
        width: 70px;
      }
      @include from-tablet-sm {
        width: 100px;
      }
      @include from-desktop-lg {
        width: 150px;
      }
    }
  }
}

.bidders-denied-access-modal {
  &__ok-btn {
    background-color: $color4;
    padding: 6px 16px;
    border-radius: 12px;
    width: 180px;
  }
}

.view-auction-details-modal {
  .ant-modal-content,
  h1 {
    background-color: $gray-4;
  }

  &__auction-items {
    max-width: 80%;
    &__holder {
      max-height: 30vh;
      @include to-tablet-sm {
        max-height: 40vh;
      }
      overflow-y: auto;
      &__item {
        background-color: white;
        border-radius: 12px;
        padding: 12px 24px;
        img {
          width: 160px;
          height: 100px;
          @include from-tablet-sm {
            width: 223px;
            height: 144px;
          }
          object-fit: cover;
          border-radius: 24px;
        }
      }
    }
  }

  &__join-btn {
    background-color: $color4;
    &:disabled {
      background-color: $gray-8;
    }
    color: white;
    padding: 8px 16px;
    border-radius: 16px;
    width: 60%;
  }
}

.join-auction-modal {
  .ant-modal-content,
  h1 {
    background-color: $gray-4;
  }
  &__group-select-holder {
    .ant-select-selector {
      border: 2px solid $color3 !important;
    }
    .ant-select-selection-item {
      font-weight: 700 !important;
      color: $color1 !important;
    }
  }
  &__group-select-dropdown {
    &__option {
      color: $color1 !important;
      &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $color3 !important;
        color: white !important;
      }
    }
  }
  &__submit-btn {
    background-color: $color4 !important;
    padding: 8px 16px;
    width: 80%;
    border-radius: 16px;
  }
}

.payment-method-modal {
  &__payment-method-radio {
    .ant-radio-inner {
      width: 18.75px;
      height: 18.75px;
      border-color: $gray-4 !important;
      outline: 2px solid $color4;
      border-width: 2px;
      background-color: transparent;
    }
    .ant-radio-checked .ant-radio-inner {
      background-color: $color4 !important;
      &::after {
        content: none;
      }
    }
  }
}
