/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.recycle-nav {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #001c30ed;
  position: sticky;
  top: 0;
  z-index: 100;
  @include from-tablet-sm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @include from-desktop-lg {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  @include from-larger-screens {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-toggler {
    background-color: $color9;
  }

  &__more-dropdown {
    &__toggle-btn {
      &.ant-dropdown-open {
        svg {
          fill: $color4;
        }
        span {
          color: $color4;
        }
      }
    }
    ul {
      background-color: $color1 !important;
      box-shadow: #ffffff17 0px 3px 5px !important;
      li {
        padding: 8px 16px;
      }
      .nav-link {
        &.active {
          .recycle-nav__nav {
            &-titles {
              color: $color4;
            }
            &-icon {
              fill: $color4;
            }
          }
        }
      }
      .nav-item {
        &:hover {
          .recycle-nav__nav {
            &-icon {
              fill: $color4;
            }
            &-titles {
              color: $color4;
            }
          }
        }
      }
    }
  }

  &__logo {
    -o-object-fit: cover;
    object-fit: cover;
    @include to-tablet-sm {
      width: 74px;
    }
    @include from-tablet-sm {
      width: 72px;
      &.rtl {
        margin-right: 10px;
      }
      &.ltr {
        margin-left: 10px;
      }
    }
    @media (min-width: 1024px) {
      width: 80px;
    }
    @include from-desktop-lg {
      width: 100px;
    }
  }

  &__offers-count {
    background-color: $color6-text;
    color: $color1;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    font-weight: 700;
    &.active-offer {
      background-color: $error;
      color: $color6-text;
    }
    @include to-tablet-sm {
      width: 14px;
      font-size: 8px;
      height: 13px;
      left: 0;
      -webkit-transform: translate(-7px, 0px);
      -ms-transform: translate(-7px, 0px);
      transform: translate(-7px, 0px);
    }
    @include from-tablet-sm {
      width: 16px;
      height: 16px;
      font-size: 9px;
      right: 0;
      -webkit-transform: translate(10px, -6px);
      -ms-transform: translate(10px, -6px);
      transform: translate(10px, -6px);
    }
    @include from-desktop-lg {
      width: 24px;
      height: 24px;
      font-size: 14px;
      -webkit-transform: translate(10px, -7px);
      -ms-transform: translate(10px, -7px);
      transform: translate(10px, -7px);
    }
  }

  &__nav-titles {
    color: $navbar-text;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  &__nav-icon {
    -webkit-transition: fill 0.3s ease;
    -o-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    @include to-tablet-sm {
      width: 22px;
    }
    @include from-tablet-sm {
      width: 24px;
      height: 24px;
    }
    @include from-desktop-lg {
      width: 32px;
      height: 32px;
    }
    @include from-larger-screens {
      width: 50px;
      height: 35px;
    }
  }

  .navbar-nav {
    @include to-tablet-sm {
      padding-left: 2px;
      padding-bottom: 16px;
      padding-top: 16px;
    }
    gap: 16px;
    @include from-tablet-lg {
      gap: 26px;
    }
    @include from-desktop-lg {
      gap: 32px;
    }
  }

  .nav-item-disabled {
    .link-holder {
      width: fit-content;
      cursor: pointer;
      span,
      svg {
        filter: brightness(0.4);
      }
    }
  }

  .nav-link {
    &.active {
      .recycle-nav__nav {
        &-titles {
          color: $color4;
        }
        &-icon {
          fill: $color4;
        }
      }
    }
  }

  .nav-item {
    &:hover {
      .recycle-nav__nav {
        &-icon {
          fill: $color4;
        }
        &-titles {
          color: $color4;
        }
      }
    }
    &__flash-icon {
      animation: flashMove 1.2s linear infinite both;
      width: 17px;
      top: 2px;
      right: -25px;
      &--ar {
        right: unset;
        left: -21px;
      }
      @include to-tablet-sm {
        &--ar {
          animation: flashMoveReverse 1.2s linear infinite both;
        }
      }
      @include from-tablet-sm {
        width: 19px;
        top: 20%;
        right: 3px;
      }
      @include from-tablet-lg {
        width: 17px;
        top: 19%;
        right: 5px;
      }
      @include from-desktop-lg {
        width: 23px;
        top: 22%;
        right: -5px;
      }
    }
  }
}

@keyframes flashMove {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(45deg);
  }

  100% {
    transform: rotateZ(0);
  }
}

@keyframes flashMoveReverse {
  0% {
    transform: rotateZ(45deg);
  }
  50% {
    transform: rotateZ(90deg);
  }

  100% {
    transform: rotateZ(45deg);
  }
}

.buy-and-supply__dropdown {
  .ant-dropdown-menu {
    background-color: $color1;
  }
  &__link {
    &__title {
      color: white;
    }
    &.active {
      span {
        color: $color4;
      }
      svg {
        fill: $color4;
      }
    }
  }
}
