/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.founders {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  padding-inline: 40px;
  -webkit-padding-before: 30px;
          padding-block-start: 30px;
  -webkit-padding-after: 10px;
          padding-block-end: 10px;

  &__mirror {
    height: 855px;
    padding-inline: 5.125rem;
    padding-block: 2.125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  &__subtitle {
    color: #dddada;
    font-size: 1.5625rem;
    font-weight: 500;
  }
  &__dots {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 32px;
    border-radius: 15px;
    background-color: #dddada;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-inline: 10px;
    margin-block: 5px;
  }
  &__dot-fig {
    width: 30px;
    height: 30px;
    -o-object-fit: cover;
       object-fit: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 7px;
  }
  &__dot {
    width: 100%;
    height: 100%;
  }
}

@include to-tablet-lg {
  .founders {
    padding-inline: 30px;
    &__mirror {
      padding-inline: 10px;
      padding-block: 1.125rem;
      height: 890px;
    }
  }
}
@include to-tablet-sm {
  .founders {
    &__mirror {
      height: 1130px;
    }
    &__dot-fig {
      width: 35px;
      height: 35px;
      padding: 10px;
    }
  }
}
@include to-mobile-sm {
  .founders {
    &__subtitle {
      font-size: 16px;
    }
    &__mirror {
      height: 1170px;
    }
  }
}