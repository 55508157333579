/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.about-us {
  &__first-section {
    background-image: url("../images/about-first-section.png");
    background-size: cover;
    background-color: rgba(0, 28, 48, 0.9);
    background-blend-mode: multiply;
  }

  &__second-section {
    background-image: url("../images/about-second-section.png");
    background-size: cover;
    background-color: rgba(13, 177, 75, 33%);
    background-blend-mode: multiply;
  }

  &__join-section {
    background-size: cover;
    mix-blend-mode: screen;
    .text-holder {
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      -webkit-backdrop-filter: blur(30px);
              backdrop-filter: blur(30px);
    }
  }

  &__video-section {
    video {
      width: 100%;
      background-image: url("../images/stars-bg.png");
      background-size: cover;
    }

    .video-overlay {
      border: 1px solid rgba(112, 112, 112, 1);
      -webkit-transition: opacity 0.5s ease;
      -o-transition: opacity 0.5s ease;
      transition: opacity 0.5s ease;
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 28, 48, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      button {
        z-index: 5;
      }
      &.hide {
        opacity: 0;
      }
    }
  }
}

@include to-tablet-sm {
  .about-us {
    &__section {
      padding-left: 23px !important;
      padding-right: 23px !important;
    }

    &__first-section {
      padding-top: 10px;
      padding-bottom: 39px;
    }

    h1 {
      padding-bottom: 21px;
    }

    &__second-section {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__join-section {
      height: 213px;
      background-image: url("../images/about-join-section-sm.png");
      .text-holder {
        width: 242px;
        padding-left: 17px;
        padding-right: 11px;
        font-size: 12px !important;
        .fsize-4 {
          font-size: 0.75rem;
        }
      }
    }

    &__video-section {
      padding: 20px 18px 49px;
      .row {
        padding-top: 15px;
        padding-bottom: 26px;
        border-radius: 24px;
        border: 4px solid rgba(231, 231, 231, 1);
        .text-holder {
          padding-left: 13px !important;
          font-size: 10px !important;
            .fsize-7, .fsize-4 {
              font-size: 0.625rem;
          }
        }
        video {
          height: 150px;
        }
        .video-overlay {
          .video-play-icon {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

@include from-tablet-sm {
  .about-us {
    &__section {
      padding-left: 47px !important;
      padding-right: 47px !important;
    }

    &__first-section {
      padding-top: 25px;
      padding-bottom: 42px;
    }

    h1 {
      padding-bottom: 24px;
    }

    &__second-section {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__join-section {
      height: 364px;
      background-image: url("../images/about-join-section-md.png");
      .text-holder {
        width: 414px;
        padding-left: 19px;
        padding-right: 19px;
      }
    }

    &__video-section {
      padding: 77px 30px;
      .row {
        padding-top: 26px;
        padding-bottom: 26px;
        border-radius: 24px;
        border: 4px solid rgba(231, 231, 231, 1);
        .text-holder {
          padding-left: 30px !important;
        }
        video {
          height: 193px;
        }
        .video-overlay {
          .video-play-icon {
            width: 55px;
            height: 55px;
          }
        }
      }
    }
  }
}

@include from-tablet-lg {
  .about-us {
    &__join-section {
      height: 439px;
      .text-holder {
        width: 500px;
        padding-left: 80px;
        padding-right: 83px;
      }
    }

    &__video-section {
      .row {
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 52px;
        .text-holder {
          padding-left: 49px !important;
        }
        video {
          height: 280px;
        }
        .video-overlay {
          .video-play-icon {
            width: 65px;
            height: 65px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1440px) {
  .about-us {
    &__video-section {
      .row {
        .text-holder {
          .fsize-7 {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-us {
    &__video-section {
      .row {
        .text-holder {
          .fsize-7 {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
}

@include from-desktop-lg {
  .about-us {
    &__section {
      padding-left: 125px !important;
      padding-right: 93px !important;
    }

    &__first-section {
      padding-top: 59px;
      padding-bottom: 59px;
    }

    h1 {
      padding-bottom: 59px;
    }

    &__second-section {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    &__join-section {
      height: 600px;
      background-image: url("../images/about-join-section-desktop.png");
      .text-holder {
        width: 803px;
        padding-left: 80px;
        padding-right: 83px;
      }
    }

    &__video-section {
      padding: 56px 59px 162px;
      .row {
        border: 10px solid rgba(231, 231, 231, 1);
        padding-top: 54px;
        padding-bottom: 45px;
        .text-holder {
          padding-left: 49px !important;
        }
        video {
          height: 387px;
        }
        .video-overlay {
          .video-play-icon {
            width: 78px;
            height: 78px;
          }
        }
      }
    }
  }
}

@include from-larger-screens {
  .about-us {
    &__section {
      padding-left: 181px !important;
      padding-right: 151px !important;
    }

    &__first-section {
      padding-top: 155px;
      padding-bottom: 60px;
    }

    h1 {
      padding-bottom: 94px;
    }

    &__second-section {
      padding-top: 71px;
      padding-bottom: 71px;
    }

    &__join-section {
      height: 718px;
      background-image: url("../images/about-join-section-lg.png");
      .text-holder {
        width: 1070px;
        padding-left: 181px;
      }
    }

    &__video-section {
      padding: 146px 53px;
      .row {
        border: 10px solid rgba(231, 231, 231, 1);
        padding-top: 75px;
        padding-bottom: 75px;
        border-radius: 52px;
        .text-holder {
          padding-left: 126px !important;
        }
        video {
          height: 540px;
        }
        .video-overlay {
          .video-play-icon {
            width: 109px;
            height: 109px;
          }
        }
      }
    }
  }
}