/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

// start buttons
.buy-item-btn {
  padding-block: 8px;
  padding-inline: 16px;
  border-radius: 31px;
  background-color: $color1;
  color: $color6-text;
  :hover {
    background-color: $color4;
  }
}
.sell-item-btn {
  width: 11.3125rem;
  padding-block: 3.2px;
  padding-inline: 8px;
  border-radius: 31px;
  background-color: $color1;
  color: $color6-text;
  :hover {
    background-color: $color4;
  }
}
//sign-in screen
.sign-in-btn {
  padding-block: 4px;
  padding-inline: 20px;
  width: 75%;
  border-radius: 8px;
  background-color: $color4;
  color: $color6-text;
}
.sign-in-btn:hover {
  color: $color6-text !important;
}
.sign-up-btn a:hover {
  color: $color4;
  text-decoration: underline;
}
#rememberMe + label {
  cursor: pointer;
}
@include to-tablet-sm {
  .sign-up-btn,
  .sign-in-btn {
    font-size: 0.8rem;
  }
  .auth-checkbox {
    width: 11px;
    height: 11px;
  }
}
.auth-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  // border: 0.5px solid $color7-nav-text;
  -webkit-box-shadow: 0px 0px 0px 1px $color4;
  box-shadow: 0px 0px 0px 1px $color4;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s ease, outline 0.3s ease;
  -o-transition: background-color 0.3s ease, outline 0.3s ease;
  transition: background-color 0.3s ease, outline 0.3s ease;
}
.auth-checkbox + label {
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.auth-checkbox.error {
  -webkit-box-shadow: 0px 0px 0px 1px $color-10;
  box-shadow: 0px 0px 0px 1px $color-10;
}
.auth-checkbox.error + label {
  color: $color-10;
}
.auth-checkbox,
.terms-text {
  cursor: pointer;
}
.auth-checkbox:checked {
  background-image: url("../icons/check-solid.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  background-color: $color4;
}
//Otp screens

.green-matter-section {
  width: 100%;
  min-height: 100vh;
  &__content {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
// start Texts
.lbl-text {
  font-size: 16px;
  color: $color7-nav-text;
}
.lbl-text-hover {
  color: $color4;
}
.lbl-text:hover {
  color: $color4;
}
// end Texts

// start navbar
.img-wrapper {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
}
.navbar-icons {
  width: 100%;
  height: 100%;
}
.navbar-icons:hover {
  color: $color4;
}
.logo {
  width: 50px;
  // height: 50px;
  @include from-tablet-sm {
    width: 70px;
  }
  @include from-desktop-sm {
    width: 90px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
}
@include to-desktop-sm {
  .img-wrapper {
    width: 25px;
    height: 25px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

// modal
.terms-para {
  color: $color1;
}
