.merchant {
  &__header {
    background-image: url("../images/buy-and-supply-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 191px;
    @include from-tablet-sm {
      min-height: 256px;
    }
    @include from-tablet-lg {
      min-height: 270px;
    }
    @include from-desktop-sm {
      min-height: 305px;
    }
    h1 {
      margin-bottom: 37px;
      @include from-tablet-sm {
        margin-bottom: 8px;
      }
    }
  }
  &__types {
    padding: 32px 10px;
    @include from-tablet-sm {
      padding: 70px 78px;
    }
    @include from-desktop-sm {
      padding: 66px 120px;
    }
    .row {
      row-gap: 24px;
      @include from-tablet-sm {
        row-gap: 50px;
      }
      @include from-desktop-sm {
        row-gap: 32px;
      }
    }
    &__type {
      border-radius: 10px;
      @include from-tablet-sm {
        border-radius: 16px;
      }
      &__image {
        background-color: $color1;
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 156px;
        @include from-tablet-sm {
          height: 278px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
      &__info {
        background-color: white;
        padding: 16px 13px;
        @include from-tablet-sm {
          padding: 32px 24px;
        }
        h2 {
          margin-bottom: 14px;
          @include from-tablet-sm {
            margin-bottom: 20px;
          }
        }
        &__price-holder {
          background-color: $secondary-4;
          border: 1px solid #176b874d;
          &[dir=rtl]{
            border-right: 0;
          }
          &[dir=ltr]{
            border-left: 0;
          }
          border-radius: 5px;
          @include from-tablet-sm {
            border-radius: 8px;
          }
          &__title {
            background-color: $secondary-8;
            width: 52%;
            border-radius: 5px;
            &[dir="ltr"] {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
            }
            &[dir="rtl"] {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            @include from-tablet-sm {
              border-radius: 8px;
              &[dir="rtl"] {
                border-bottom-left-radius: 16px;
                border-top-left-radius: 16px;
              }
              &[dir="ltr"] {
                border-bottom-right-radius: 16px;
                border-top-right-radius: 16px;
              }
            }
          }
          &__value {
            gap: 4px;
            padding-block: 2px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            @include from-tablet-sm {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
      button {
        background-color: $color4;
        padding-block: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        @include from-tablet-sm {
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
  }
  &__sell-modal {
    .ant-modal-content {
      background-color: $gray-4;
      min-width: 335px;
      min-height: 223px;
      border-radius: 24px;
      padding: 32px 17px;
      @include from-tablet-sm {
        padding: 32px;
        width: 664px;
        min-height: 284px;
      }
      @include from-tablet-lg {
        min-height: 302px;
        padding: 40px;
        width: 880px;
      }
      @include from-desktop-sm {
        min-height: 350px;
        padding: 40px 92.5px;
        width: 1050px;
      }
    }
    h1 {
      margin-bottom: 24px;
      @include from-tablet-sm {
        margin-bottom: 40px;
      }
      @include from-desktop-sm {
        margin-bottom: 56px;
      }
    }
    &__types-holder {
      overflow-y: auto;
      row-gap: 12px;
      margin-bottom: 24px;
      max-height: 480px;
      @include from-tablet-sm {
        margin-bottom: 40px;
        max-height: 406px;
      }
      @include from-tablet-lg {
        row-gap: 16px;
        max-height: 477px;
      }
      @include from-desktop-sm {
        margin-bottom: 56px;
      }
      &__type {
        background-color: white;
        border-radius: 12px;
        padding: 12px;
        gap: 24px;
        @include from-tablet-sm {
          padding: 12px 24px;
        }
        &__checkbox {
          .ant-checkbox-inner {
            border-width: 1.5px;
            border-color: $color4 !important;
            width: 36px;
            height: 36px;
            border-radius: 6px;
            &:after {
              left: 29%;
              width: 8px;
              height: 15px;
            }
          }
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: $color4 !important;
            }
            &:after {
              border-color: $color4 !important;
            }
          }
        }
        &__image {
          height: 144px;
          max-width: 100%;
          border-radius: 24px;
          object-fit: cover;
          width: 277px;
          @include from-tablet-sm {
            width: 144px;
          }
          @include from-tablet-lg {
            width: 223px;
          }
        }
        .ant-form-item {
          margin-bottom: 35px;
        }
        &__weight-input {
          border-width: 2px !important;
          &:not(.ant-input-number-status-error) {
            border-color: $secondary-8 !important;
          }
          &.ant-input-number-disabled {
            background-color: $gray-6;
          }
          input {
            height: 42px !important;
            min-height: 42px !important;
            font-weight: 700;
          }
        }
      }
    }
    &__profit-holder {
      row-gap: 24px;
      justify-content: center;
      @include from-tablet-lg {
        justify-content: flex-start;
      }
      & > div {
        @include to-tablet-lg {
          width: fit-content;
        }
      }
    }
    &__profit-col {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include from-tablet-lg {
        width: fit-content;
      }
      &__info {
        background-color: $primary-6;
        padding: 8px 12px;
        border-radius: 50px;
        min-width: 200px;
        @include to-tablet-sm {
          font-size: 0.75rem;
        }
        @include from-tablet-sm {
          min-width: 305px;
        }
      }
    }
    &__submit-btn {
      width: 159px;
      background-color: $color4;
      height: 52px;
      border-radius: 8px;
      margin-top: 24px;
      &:disabled {
        background-color: $color13;
      }
      @include from-tablet-sm {
        height: 58px;
        border-radius: 16px;
        width: 380px;
        margin-top: 40px;
      }
      @include from-desktop-sm {
        margin-top: 56px;
      }
    }
    &__empty-msg {
      color: $error;
      margin-bottom: 24px;
      @include from-tablet-sm {
        margin-bottom: 40px;
      }
      @include from-desktop-sm {
        margin-bottom: 56px;
      }
    }
  }
}
