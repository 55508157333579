/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.mct-section-ourpartners {
  width: 100%;
  height: 391px !important;
}
.our-partners {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-block: 20px;
  &__subtitle {
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    color: #262d38;
    letter-spacing: 0px;
  }
  &__cards {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  &__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-margin-before: 5.125rem;
            margin-block-start: 5.125rem;
  }
  &__card-img-pic {
    width: 190px;
    height: 83px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-margin-after: 0.625rem;
            margin-block-end: 0.625rem;
  }
  &__card-img-pic {
    width: 253px;
    height: 90px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-margin-after: 0.625rem;
            margin-block-end: 0.625rem;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
  &__card-label {
    font-size: 20px;
    font-weight: 500;
    color: #262d38;
    text-align: center;
  }
}

@include to-tablet-lg {
  .our-partners {
    &__card {
      -webkit-margin-before: 1.125rem;
              margin-block-start: 1.125rem;
    }
  }
}
@include to-tablet-sm {
  .mct-section-ourpartners {
    height: 425px !important;
  }
  .our-partners {
    &__cards {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    &__card {
      -webkit-margin-before: 1.125rem;
              margin-block-start: 1.125rem;
    }
  }
}
@include to-mobile-sm {
  .our-partners {
    &__subtitle {
      font-size: 18px;
    }
    &__cards {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    &__card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-margin-before: 1.125rem;
              margin-block-start: 1.125rem;
    }

    &__card-label {
      font-size: 16px;
    }
  }
}