/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.payment-details-form {
  padding-inline: 20px;
  -webkit-padding-before: 20px;
  padding-block-start: 20px;

  .total-price-input.ant-input:hover {
    border-color: #d9d9d9 !important;
  }

  &__price-input,
  &__phone-input {
    border-radius: 35px;
    font-size: 16px;
  }

  &__phone-input {
    &__prefix {
      &-icon {
        width: 20px;
        height: 20px;
      }
      &-text {
        font-size: 0.8rem;
        color: black !important;
      }
    }
  }
}
