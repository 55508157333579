/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.items-section {
  background-image: url("../images/stars-bg.png");
  background-size: cover;
  .ant-spin-nested-loading {
    width: 100%;
  }
}

.exchangeModal {
  .ant-modal-content {
    min-height: 300px;
    background-color: $gray-4;
  }
  &__label {
    color: $exchange-label;
  }
  &__item-name {
    @include from-tablet-sm {
      max-width: 16ch;
    }
  }
  .ant-radio-wrapper .ant-radio-inner {
    background-color: transparent !important;
    border: 1.5px solid $exchange-label !important;
  }
  .ant-radio-wrapper .ant-radio-inner::after {
    background-color: $exchange-label !important;
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.7) !important;
  }
  &__items-holder {
    max-height: 250px;
    overflow-y: auto;
  }
  &__item {
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px;
    &__img {
      width: 84px;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
    }
    .ant-checkbox-inner {
      width: 16px !important;
      height: 16px !important;
      border: none;
      border-radius: 2px;
      box-shadow: 0 0 0 1.5px $color4 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color4 !important;
    }
    .ant-checkbox-checked:after {
      border: none !important;
    }
  }
  &__submit-btn {
    width: fit-content !important;
    border-radius: 31px !important;
  }
  &__total-result {
    background-color: $color-11;
    border-radius: 35px;
    min-width: 200px;
    padding-inline: 16px;
  }
}

@include to-tablet-sm {
  .items-section {
    padding: 34px 20px;
  }
  .empty-state-img {
    width: 100px;
    height: 110px;
  }
  .exchangeModal {
    &__items-holder {
      height: 300px;
    }
    &__item {
      background-color: #ffffff;
      border-radius: 24px;
      padding: 20px;
      &__img {
        max-width: 100px;
        width: auto;
        height: auto;
        aspect-ratio: 3/2;
        border-radius: 10px;
      }
    }
  }
}

@include from-tablet-sm {
  .items-section {
    padding: 54px 40px;
  }
  .empty-state-img {
    width: 200px;
    height: 220px;
  }
}

@include from-desktop-lg {
  .items-section {
    padding: 80px 120px;
  }
  .empty-state-img {
    width: 350px;
    height: 385px;
  }
}

@include from-larger-screens {
  .items-section {
    padding: 119px 170px;
  }
}

.items {
  width: 100%;
  min-height: 100vh;
  &__card {
    background-color: $color5-popup-bg;
  }
  &__img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &__paragraph {
    color: $color4;
  }
  &__cart {
    background-color: $color3;
    color: $color6-text;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  &__buy-btn {
    background-color: $color1;
    color: $color6-text;
    border: 4px solid $color1;
  }
  &__buy-btn:not(:disabled):hover {
    background-color: $color4;
    color: $color6-text !important;
    border-color: $color4 !important;
  }
  &__exchange-btn {
    color: $color1;
    background-color: $color6-text;
  }
  &--store-item {
    &__description {
      &__label {
        color: $color3;
        span {
          font-size: 20px;
        }
      }
    }
    &__buy-input {
      border-width: 2px !important;
      border-style: solid !important;
      &:not(.ant-input-number-status-error) {
        border-color: $color3 !important;
      }
      div,
      input {
        height: 100% !important;
      }
    }
    &__buy-btn {
      &:not(:disabled) {
        background-color: $color4 !important;
      }
      &:disabled {
        background-color: $color13 !important;
      }
    }
    &__exchange-btn {
      color: $color4 !important;
    }
    &__exchange-btn,
    &__buy-btn {
      border: 4px solid $color4 !important;
      border-radius: 16px;
    }
    &__buy-input,
    &__buy-btn {
      height: 41px !important;
      @include from-desktop-lg {
        height: 57px !important;
      }
    }
    &__total-price {
      background-color: $secondary-4;
      padding: 4px 12px;
      border-radius: 30px;
    }
  }
}

@include to-tablet-sm {
  .items {
    row-gap: 40px;
    &__card {
      padding: 23px 21px;
      border-radius: 24px;
    }
    &__fig {
      height: 195px;
      margin-bottom: 29px;
    }
    &__img {
      border-radius: 24px;
    }
    &__paragraph {
      margin-bottom: 18px;
    }
    &__cart {
      border-radius: 31px;
      padding-block: 5px;
      width: 100%;
    }
    &__cart-details {
      margin-bottom: 18px;
    }
    &__buy-btn {
      font-size: 1rem;
      border-radius: 31px;
      padding-top: 9px;
      padding-bottom: 9px;
      width: 225px;
    }
    &__quantity {
      font-size: 1.25rem;
    }
  }
}

@include from-tablet-sm {
  .items {
    row-gap: 40px;
    &__card {
      padding: 24px 0 24px 20px;
      border-radius: 24px;
    }
    &__fig {
      height: 220px;
    }
    &__img {
      border-radius: 24px;
    }
    &__paragraph {
      margin-bottom: 21px;
    }
    &__cart {
      border-radius: 31px;
      padding-block: 8px;
      min-width: 144px;
    }
    &__cart-details {
      margin-bottom: 18px;
    }
    &__buy-btn {
      border-radius: 31px;
      width: 240px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .items {
    &__buy-btn {
      font-size: 1rem;
    }
  }
}

@include from-tablet-lg {
  .items {
    row-gap: 90px;
    &__card {
      padding: 38px 0 38px 38px;
      border-radius: 64px;
    }
    &__fig {
      height: 342px;
    }
    &__img {
      border-radius: 55px;
    }
    &__paragraph {
      margin-bottom: 34px;
    }
    &__cart {
      border-radius: 31px;
      padding-block: 10px;
      min-width: 180px;
    }
    &__cart-details {
      margin-bottom: 27px;
    }
    &__buy-btn {
      width: 381px;
      border-radius: 31px;
    }
  }
}

@include from-desktop-lg {
  .items {
    row-gap: 80px;
    &__card {
      padding: 38px 0 38px 38px;
      border-radius: 64px;
    }
    &__fig {
      height: 438.21px;
    }
    &__img {
      border-radius: 55px;
    }
    &__paragraph {
      margin-bottom: 34px;
    }
    &__cart {
      border-radius: 31px;
      padding-block: 13px;
      min-width: 220px;
    }
    &__cart-details {
      margin-bottom: 27px;
    }
    &__buy-btn {
      width: 100%;
      border-radius: 31px;
    }
  }
}

@include from-larger-screens {
  .items {
    row-gap: 144px;
    &__card {
      padding: 48px 0 48px 48px;
      border-radius: 64px;
    }
    &__fig {
      height: 544px;
    }
    &__paragraph {
      margin-bottom: 47px;
    }
    &__cart {
      padding-block: 13px;
    }
    &__cart-details {
      margin-bottom: 27px;
    }
  }
}
