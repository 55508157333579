/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.landing-page {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: url("../images/landing-page-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 28, 48, 0.8);
  background-blend-mode: multiply;
}

@include to-tablet-sm {
  .landing-page {
    height: 30vh;
  }
  .landing-title {
    padding-left: 20px;
  }
}

@include from-tablet-sm {
  .landing-page {
    height: 70vh;
  }
  .landing-title {
    padding-left: 50px;
  }
}

@include from-desktop-lg {
  .landing-title {
    padding-left: 176px;
  }
}