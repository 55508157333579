.buy-and-supply {
  &__header {
    background-image: url("../images/buy-and-supply-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 116px;
    margin-bottom: 43px;
    @include from-tablet-sm {
      margin-bottom: 73px;
      height: 195px;
    }
    @include from-tablet-lg {
      height: 187px;
      margin-bottom: 66px;
    }
    @include from-desktop-sm {
      margin-bottom: 76px;
    }
    @include from-desktop-lg {
      margin-bottom: 87px;
    }
  }
  &__tabs-holder {
    transform: translateY(50%);
    padding-inline: 12px;
    @include from-tablet-sm {
      padding-inline: 36px;
    }
    @include from-tablet-lg {
      padding-inline: 50px;
    }
    @include from-desktop-sm {
      padding-inline: 70px;
    }
    @include from-desktop-lg {
      padding-inline: 120px;
    }
    &__main-holder {
      background-color: #ffffff29;
      padding: 22px 6px;
      border-radius: 32px;
      @include from-tablet-sm {
        padding: 37px 20px;
        border-radius: 43px;
      }
      @include from-tablet-lg {
        padding: 30px 40px;
        border-radius: 34px;
      }
      @include from-desktop-sm {
        padding: 40px 50px;
        border-radius: 37px;
      }
      @include from-desktop-lg {
        padding: 51px 77px;
        border-radius: 43px;
      }
      &__tab {
        width: 98px;
        height: 40px;
        &--pending-orders {
          width: 108px;
        }
        @include to-tablet-sm {
          padding-inline: 1px !important;
          font-size: 0.875rem;
        }
        @include to-mobile-md {
          padding-inline: 12px !important;
          width: auto;
        }
        @include from-mobile-sm {
          width: 25%;
        }
        @include from-tablet-sm {
          height: 72px;
          width: 175px;
          &--balance {
            width: 166px;
          }
          &--pending-orders {
            width: 243px;
          }
        }
        @include from-tablet-lg {
          width: 220px;
        }
        @include from-desktop-sm {
          width: 282px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px !important;
        &,
        &:hover {
          color: $gray-8;
        }
        &.ant-radio-button-wrapper-checked {
          background-color: $color4 !important;
          border-color: $color4 !important;
        }
        &::before {
          content: none !important;
        }
      }
    }
  }
  &__items-holder {
    padding: 38px 22px;
    &--balance {
      padding-inline: 16px;
    }
    @include from-tablet-sm {
      padding-inline: 78px;
      padding-block: 100px;
      &--balance {
        padding-inline: 40px;
      }
    }
    @include from-tablet-lg {
      padding-block: 60px;
      padding-inline: 50px;
    }
    @include from-desktop-sm {
      padding-block: 100px;
      padding-inline: 70px;
    }
    @include from-desktop-lg {
      padding-inline: 120px;
    }
    &__items {
      row-gap: 30px;
      @include from-tablet-sm {
        row-gap: 56px;
      }
      @include from-tablet-lg {
        row-gap: 50px;
      }
      @include from-desktop-sm {
        row-gap: 60px;
      }
      @include from-desktop-lg {
        row-gap: 84px;
      }
      & > div {
        @include to-tablet-sm {
          padding-inline: 0 !important;
        }
      }
    }
  }
  &__card {
    border-radius: 9px;
    @include from-tablet-sm {
      border-radius: 16px;
    }
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      height: 200px;
      @include from-tablet-sm {
        height: 357px;
      }
      @include from-tablet-lg {
        height: 270px;
      }
      @include from-desktop-sm {
        height: 300px;
      }
      @include from-desktop-lg {
        height: 357px;
      }
    }
    &__data-holder {
      background-color: #ffffff;
      padding: 18px 13px;
      @include from-tablet-sm {
        padding: 24px;
      }
      @include from-desktop-lg {
        padding-block: 32px;
      }
    }
    &__boxes-holder {
      gap: 19px;
      @include from-tablet-sm {
        gap: 25px;
      }
      @include from-desktop-lg {
        gap: 34px;
      }
      div {
        width: 100%;
        border: 1px solid #176b874d;
        border-radius: 8px;
        padding-inline: 5px;
        height: 106px;
        @include from-desktop-lg {
          width: 240px;
        }
      }
      &__value {
        display: block;
        max-width: 100%;
      }
    }
    &--supply {
      &__boxes-holder {
        gap: 13px;
        div {
          height: 64px;
          @include from-tablet-sm {
            height: 84px;
          }
          background-color: $secondary-8;
        }
      }
    }
    &__buy-btn {
      background-color: $color4;
      padding-block: 8px;
      @include from-tablet-sm {
        padding-block: 12px;
      }
      @include from-tablet-lg {
        padding-block: 7px;
      }
      @include from-desktop-lg {
        padding-block: 4px;
      }
      &:disabled {
        background-color: $gray-8;
      }
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &__empty-state {
    p {
      color: $secondary-4;
    }
    img {
      width: 82px;
      @include from-tablet-sm {
        width: 154px;
      }
      @include from-desktop-lg {
        width: 179px;
      }
    }
    &--balance {
      img {
        width: 96px;
        margin-bottom: 46px;
        @include from-tablet-sm {
          width: 223px;
          margin-bottom: 100px;
        }
        @include from-desktop-lg {
          margin-bottom: 136px;
        }
      }
    }
    &--pending-orders {
      img {
        width: 76px;
        margin-bottom: 49px;
        @include from-tablet-sm {
          width: 198px;
          margin-bottom: 128px;
        }
      }
    }
  }
  &__buy-modal {
    input {
      color: $secondary-8 !important;
      font-weight: 700;
      &::placeholder {
        color: $gray-8 !important;
      }
    }
    &__select {
      .ant-select-selector {
        display: flex;
        align-items: center;
        border: 2px solid $secondary-8 !important;
        .ant-select-selection-item {
          font-weight: 700;
          color: $secondary-8;
          font-size: 1rem;
          @include from-tablet-sm {
            font-size: 1rem;
          }
          @include from-desktop-lg {
            font-size: 1.5rem;
          }
        }
      }
      &__option {
        .ant-select-item-option-content {
          font-size: 1rem !important;
          @include from-tablet-sm {
            font-size: 1rem !important;
          }
          @include from-desktop-lg {
            font-size: 1.5rem !important;
          }
        }
      }
    }
    input,
    &__select .ant-select-selector {
      height: 56px !important;
    }
  }
  &__balance-holder {
    gap: 24px;
    @include from-tablet-sm {
      gap: 38px;
    }
    @include from-desktop-lg {
      padding-top: 40px;
      gap: 56px;
    }
  }
  &__balance-row {
    background-color: $color4;
    border-radius: 8px;
    gap: 5px;
    padding-inline: 11px;
    min-height: 55px;
    @include from-tablet-sm {
      min-height: 66px;
      gap: 20px;
      padding-inline: 23px;
    }
    @include from-tablet-lg {
      gap: 50px;
      padding-inline: 80px;
    }
    @include from-desktop-lg {
      padding-right: 145px;
      padding-left: 120px;
    }
    p {
      padding: 8px;
      max-width: 26ch;
      @include from-tablet-lg {
        max-width: 36ch;
      }
      @include from-desktop-lg {
        max-width: 31ch;
      }
    }
    &__value-holder {
      padding-inline: 8px;
      border-radius: 8px;
      background-color: white;
      width: 75px;
      @include from-tablet-sm {
        width: 222px;
      }
      @include from-desktop-lg {
        width: 311px;
      }
    }
  }
  &__supply-modal {
    .ant-radio-inner {
      border-color: $gray-8;
      border-width: 2px;
    }
    .ant-radio {
      align-self: auto;
      top: 3px;
    }
    &__radio-holder {
      gap: 32px !important;
    }
  }
  &__pending-orders-holder {
    row-gap: 48px;
    @include from-tablet-lg {
      row-gap: 56px;
    }
    & > div {
      @include to-tablet-lg {
        padding: 0 !important;
      }
    }
    &__pending-order-card {
      overflow: hidden;
      background-color: white;
      border-radius: 9px;
      @include from-tablet-sm {
        border-radius: 16px;
      }
      &__first-holder {
        padding: 18px 13px 13px;
        @include from-tablet-sm {
          padding: 32px 24px 24px;
        }
        &__data-holder {
          &_boxes-holder {
            padding-top: 13px;
            gap: 19px;
            @include from-tablet-sm {
              padding-top: 24px;
              gap: 34px;
            }
            & > div {
              background-color: $secondary-8;
              width: 100%;
              padding-block: 11px;
              border-radius: 4px;
              gap: 4px;
              @include from-tablet-sm {
                border-radius: 8px;
                gap: 8px;
                padding-block: 20px;
                width: 237px;
              }
            }
            &__value {
              span {
                max-width: 8ch;
                @include from-mobile-sm {
                  max-width: 20ch;
                }
                @include from-tablet-sm {
                  max-width: 16ch;
                }
                @include from-tablet-lg {
                  max-width: 11ch;
                }
                @include from-desktop-lg {
                  max-width: 9ch;
                }
              }
            }
          }
        }
      }
      &__second-holder {
        background-color: $gray-4;
        padding: 13px 24px 4px;
        @include from-tablet-sm {
          padding: 24px 44px 8px;
        }
        & > div {
          margin-bottom: 8px;
          @include from-tablet-sm {
            margin-bottom: 16px;
          }
        }
        p {
          color: $color13;
        }
      }
    }
  }
}
