/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.mct-section-footer {
  width: 100%;
  background-color: $main-color;
  color: $first-color;
}
.footer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-padding-before: 20px;
          padding-block-start: 20px;
  -webkit-padding-after: 10px;
          padding-block-end: 10px;
  &__data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  &__logo-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  &__logo {
    width: 190px;
    height: 97px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-margin-after: 2.625rem;
            margin-block-end: 2.625rem;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
  &__address-location {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  &__location {
    width: 45px;
    height: 45px;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-margin-end: 0.875rem;
            margin-inline-end: 0.875rem;
  }
  &__address {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.8px;
    color: #ffffff;
    opacity: 1;
  }
  &__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    ul {
      padding: 0;
    }
  }
  &__link {
    font-size: 20px;
    font-weight: 600;
  }
  &__link:hover {
    color: #a7c0e8;
  }
  &__copy-rights {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  &__rights {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0px;
  }
}

@include to-tablet-lg {
  .footer {
    &__address {
      font-size: 16px;
    }
    &__link {
      font-size: 16px;
    }
    &__rights {
      font-size: 14px;
    }
  }
}
@include to-mobile-sm {
  .footer {
    &__data {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
    }
    &__logo-address {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-margin-before: 10px;
              margin-block-start: 10px;
    }
    &__logo {
      -webkit-margin-after: 1.625rem;
              margin-block-end: 1.625rem;
    }
    &__address {
      font-size: 14px;
      font-weight: 400;
    }
    &__link {
      font-size: 14px;
    }
    &__copy-rights {
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: start;
    }
    &__rights {
      font-size: 14px;
      color: $first-color;
    }
  }
}