.terms-modal {
  z-index: 1060 !important;
  &-root {
    .ant-modal-mask {
      z-index: 1050 !important;
    }
  }
  .ant-modal {
    @include from-desktop-sm {
      width: 55vw !important;
    }

    @include from-desktop-lg {
      width: 50vw !important;
    }
  }
}

.terms-modal-viewer {
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 4000;
  height: 100vh;
}

.notificationModal {
  &__items-holder {
    max-height: 44vh;
    overflow-y: auto;
  }
  &__chooseCollectionTypeMsg {
    max-width: 500px;
  }
  .ant-modal-content {
    background-color: $gray-4;
  }
}
