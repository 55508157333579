.auction-requests-drawer {
  &__requests-holder {
    gap: 24px;
    &__request {
      &__auction-status {
        min-width: 100px;
        @include from-tablet-sm {
          min-width: 120px;
        }
        @include from-desktop-lg {
          min-width: 141px;
        }
        padding: 4px;
      }
      &__reject-btn {
        color: $error;
        span {
          text-decoration: underline;
        }
      }
      &__accept-btn,
      &__reject-btn {
        span {
          font-family: "Cairo", sans-serif;
        }
      }
    }
  }
  &__tabs-holder {
    .ant-radio-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44%;
      height: 57px;
      border-radius: 16px;
      color: $color1 !important;
      @include from-tablet-sm {
        border-radius: 16px;
        width: 257px;
        height: 60px;
      }
      @include from-desktop-sm {
        width: 277px;
        height: 79px;
      }
      &:not(:first-child)::before {
        content: none;
      }
      &-checked {
        font-weight: 700;
        color: white !important;
        background: $color4 !important;
        border-color: $color4 !important;
      }
    }
  }
}

.auction-requests {
  &__accept-reject-modal {
    .ant-modal-content {
      background-color: $gray-4;
    }
    &__input-holder {
      max-width: 588px;
      &__input {
        .ant-input-number-group-addon {
          background-color: white;
          border: none;
        }
        &.ant-input-number-group-wrapper {
          border: 2px solid $secondary-8;
          border-radius: 8px;
          &-status-error {
            border-color: $error;
            .ant-input-number-group-addon {
              span {
                color: $error;
              }
            }
          }
        }
        &.ant-input-number {
          border: none !important;
          box-shadow: none !important;
          input {
            height: auto;
          }
        }
      }
      input {
        height: auto !important;
        &::placeholder {
          color: $gray-6;
          font-weight: 700;
        }
      }
    }
    &__confirm-btn {
      border-radius: 16px;
      background-color: $color4;
      &:disabled {
        background-color: $gray-8;
      }
    }
  }
}
