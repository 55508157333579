.store-items {
  &__home-link {
    text-decoration: none;
    width: fit-content;
    img {
      width: 12px;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
  &__payment-type-modal {
    &__select {
      .ant-select-selector {
        border: 2px solid $color3 !important;
        border-radius: 8px;
        .ant-select-selection-item {
          font-weight: bold;
          color: $color1;
          font-size: 1rem !important;
        }
      }
    }
    &__error-msg {
      color: $error;
    }
    &__phone-input {
      border-radius: 8px;
      border-width: 2px !important;
      &:not(.ant-input-affix-wrapper-status-error):not(
          .ant-input-affix-wrapper-disabled
        ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
        border-color: $color3 !important;
      }
      input {
        color: $color1 !important;
        font-weight: 700 !important;
      }
    }
  }
}
