/* -------------------------------------------------------------------------- */
/*                               old-colors                                   */
/* -------------------------------------------------------------------------- */
$main-color: #151d41;
$first-color: #27a2d9;
$second-color: #4c60b8;
$third-color: #8f9fea;
$fourth-color: #b9c3ee;
$fifth-color: #dee3f7;
$red-color: red;
/* -------------------------------------------------------------------------- */
/*                                    new-colors                                   */
/* -------------------------------------------------------------------------- */

$color1: #001c30;
$color2: #031830;
$color3: #176b87;
$color4: #0db14b;
$primary-6: #c7e7d3;
$secondary-4: #c9d9df;
$secondary-6: #70a2b3;
$secondary-8: #176b87;
$purchase-value: #0db14b;
$purchase-value-bg: #a9e0be;
$color5-popup-bg: #e6e6e6;
$color6-text: #e7e7e7;
$color7-nav-text: #d3d3d3;
$color8-home: #c2c2c2;
$color9: #0f457a;
$color-10: #ff4242;
$color-11: #a9e0be;
$color12: #f5f5f5;
$color13: #7a7a7a;
$color14: #c9d9df;
$exchange-label: #0e4f81;
$sign-in-bg: #01182c;
$highest-offer: #e1fceb;
$offer: #f2f2f2;
$navbar-text: #ffffff;
$gray-2: #eeeeee;
$gray-3: #fafafd;
$gray-4: #f5f5f5;
$gray-6: #cdcdcd;
$gray-8: #8f8f8f;
$gray-9: #4d4d4d;
$request--pending: #ca8500;
$request--accepted: #6acc8f;
$request--rejected: #e72121;
$auction--yellow: #d1740f;
$natural--black: #111111;
$black-1: #212121;
$error: #e72121;
$green-1: #589559;

/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
