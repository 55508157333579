/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.order-type-toggler {
  .ant-radio-group {
    border-radius: 40px;
    background-color: $color12;
  }
  .ant-radio-button-wrapper {
    height: 33px;
    border: none !important;
    border-radius: 40px;
    display: flex;
    background-color: $color12;
    color: $color13;
    align-items: center;
    &::before {
      content: none;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: $color3;
    color: white !important;
  }
}

.payment-type-toggler {
  .ant-radio-button-wrapper {
    height: 33px;
    border-radius: 40px;
    display: flex;
    background-color: $color12;
    color: $color13;
    border: 1px solid $color13;
    align-items: center;
    &:before {
      content: url("../icons/payment-type-check.svg");
      position: relative !important;
      inset-block-start: 0;
      inset-inline-start: 0;
      padding-block: 0;
      background-color: transparent !important;
      transform: scale(0.7);
      opacity: 0;
      width: 0;
      height: 0;
      transition: width 0.3s ease, height 0.3s ease, opacity 0.5s ease;
    }
  }
  .ant-radio-button-wrapper-checked {
    color: $color3;
    background-color: $color14;
    border-color: $color3 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &:hover {
      color: $color3;
    }
    &:before {
      width: 23px;
      height: 26px;
      opacity: 1;
    }
  }
}

@include from-desktop-lg {
  .order-type-toggler {
    .ant-radio-button-wrapper {
      height: 40px;
    }
  }

  .payment-type-toggler {
    .ant-radio-button-wrapper {
      height: 40px;
    }
  }
}

.orders {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: auto;
  &__order {
    width: 100%;
    border-radius: 12px;
    background-color: $color14;
    margin-block: 5px;
    padding: 15px;
    &__offer-btn {
      border-radius: 30px;
      &,
      &:hover {
        color: white !important;
      }
      &--accept {
        background-color: $color4;
      }
      &--reject {
        background-color: $color-10;
      }
      &--store {
        &:not(:disabled) {
          &,
          &:hover {
            color: $color4 !important;
          }
          background-color: white;
        }
      }
      &--accept,
      &--store {
        border: 2px solid transparent !important;
        &:disabled {
          cursor: default;
          background-color: $color13;
        }
        &:not(:disabled) {
          border: 2px solid $color4 !important;
        }
      }
    }
  }
  &__paragraph,
  &__qty,
  &__title {
    color: $color3;
  }
  &__pay-btn {
    height: auto;
    border-radius: 16px;
    padding: 8px 16px !important;
    background-color: $color4;
    color: white !important;
    border: none !important;
    line-height: 1 !important;
  }
}

@include to-tablet-sm {
  .orders {
    &__no-data-img {
      width: 25%;
    }
  }
}
