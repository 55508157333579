/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

body {
  background-image: url("../images/stars-bg.png");
  // background-size: cover;
}
.global-component {
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mct-section {
  width: 100%;
  min-height: 100vh;
}

@include to-tablet-sm {
  .mct-section {
    min-height: 100svh;
  }
}
.mct-section-landing {
  width: 100%;
  min-height: 70vh;
}
.mct-section-title {
  font-size: 31.2496px;
  font-weight: bold;
  color: $main-color;
}
.mct-section-title-b {
  font-size: 31.2496px;
  font-weight: bold;
  color: #000000;
}
.mct-section-title-w {
  font-size: 31.2496px;
  font-weight: bold;
  color: #dddada;
}
.blue-title {
  color: #325998;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}
.card-parag {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #262d38;
}

.card-icon {
  width: 118px;
  height: 118px;
  position: absolute;
  top: -59px;
  left: -59px;
  -o-object-fit: cover;
     object-fit: cover;
  img {
    width: 100%;
    height: 100%;
  }
}

.image-fig {
  width: 100%;
  height: 100%;
}
.card-p {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #262d38;
  opacity: 1;
}
.active-link-nav {
  color: #a7c0e8;
  font-weight: bold;
  border-bottom: solid 2px #a7c0e8;
}
.active-link-foot {
  color: #a7c0e8;
  font-weight: bold;
}
.carousel-container {
  height: 100%;
}

.slide-content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blue-text {
  color: $first-color;
}
.dark-blue-text {
  color: $main-color;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@include to-tablet-lg {
  .mct-section-title {
    font-size: 25.2496px;
    font-weight: 500;
  }
  .card-icon {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -40px;
    left: -40px;
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@include to-mobile-sm {
  .mct-section-title {
    font-size: 20px;
  }
  .mct-section-title-w {
    font-size: 20px;
  }
  .mct-section-title-b {
    font-size: 20px;
  }
  .blue-title {
    font-size: 20px;
    font-weight: 600;
  }
  .card-icon {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -30px;
    left: -30px;
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}