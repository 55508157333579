/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.otp-form {
  width: 100%;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .otp-inputs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .otp-input {
      width: calc(100% / 6);
      height: 64px;
      font-weight: 600;
      border: solid $main-color 3px !important;
      border-radius: 5px;
      -webkit-margin-end: 5px;
      margin-inline-end: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: $fifth-color;
      text-align: center;
    }
  }
}
@include to-tablet-sm {
  .otp-form {
    padding: 0;
  }
}

.spin-overlay {
  background-color: rgba(0, 0, 0, 0.25);
}

.otp-modal {
  .ant-modal-title {
    text-align: center;
  }
}
