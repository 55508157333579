/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.offers-drawer {
  background-color: $first-color;
  color: $main-color;
  .orders {
    width: 100%;
    height: 100%;
    padding-right: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 24px;
    .accordion-item {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .accordion-button::after,
    .accordion-button:not(.collapsed)::after {
      width: 20px;
      height: 20px;
      margin-left: unset;
      background-image: url("../icons/offers-accordion-btn.svg");
    }
    .accordion-button.collapsed::after {
      transform: rotate(0deg) !important;
    }
    .accordion-button:not(.collapsed)::after {
      transform: rotate(-90deg) !important;
    }
    [dir="rtl"] .accordion-button:not(.collapsed)::after {
      transform: rotate(90deg) !important;
    }
    &__order {
      width: 100%;
      border-radius: 12px;
      background-color: $offer;
      // padding: 24px 32px;
    }
    &__category::before {
      content: "";
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: $color4;
    }
    &__paragraph,
    &__price {
      color: $color4;
    }
    &__qty,
    &__title {
      color: $main-color;
    }
  }

  .ant-drawer-header {
    background-color: #ffffff;
  }
  .ant-drawer-title {
    color: $color1;
    font-size: 32px;
    font-weight: 500;
  }
  .ant-drawer-close {
    color: $main-color;
    font-size: 30px;
  }
  .ant-drawer-body {
    background-color: $color2;
    padding-right: 0;
    overflow-y: auto;
    padding-block: 0;
  }
}

[dir="rtl"] .offers-drawer .ant-drawer-title {
  text-align: end;
}
