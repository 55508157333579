.auction-chat {
  padding: 32px 20px;
  @include from-desktop-sm {
    padding: 64px;
  }
  &__name-holder {
    border-radius: 50px;
    padding: 8px 14px;
    gap: 12px;
    background-color: $color3;
    transition: background-color 0.3s ease, color 0.3s ease;
    @include from-tablet-sm {
      gap: 24px;
      border-radius: 60px;
      padding: 12px 24px;
    }
    @include from-desktop-sm {
      gap: 32px;
    }
    @include from-desktop-lg {
      gap: 40px;
    }
    span {
      color: white;
    }
    img {
      width: 16px;
      @include from-tablet-sm {
        width: 20px;
      }
      @include from-desktop-sm {
        width: 24px;
      }
      @include from-desktop-lg {
        width: 35px;
      }
    }
    &--active {
      background-color: white;
      span {
        color: $secondary-8;
      }
    }
  }
  &__col {
    padding: 0 !important;
    @include from-tablet-lg {
      padding-inline: 12px !important;
    }
  }
  &__auction-info {
    &__clock-icon {
      width: 20px;
      @include from-tablet-sm {
        width: 28px;
      }
      @include from-tablet-lg {
        width: 32px;
      }
      @include from-desktop-lg {
        width: 40px;
      }
    }
    &__auction-items {
      overflow-y: auto;
      max-height: 50vh;
      &__item {
        background-color: white;
        border-radius: 12px;
        padding: 12px;
        max-width: 580px;
        width: 100%;
        @include from-tablet-sm {
          padding: 12px 16px;
        }
        @include from-desktop-lg {
          padding: 12px 24px;
        }
        img {
          width: 110px;
          height: 80px;
          border-radius: 16px;
          @include from-tablet-sm {
            width: 150px;
            height: 100px;
            border-radius: 16px;
          }
          @include from-desktop-lg {
            width: 223px;
            height: 144px;
            border-radius: 24px;
          }
        }
      }
    }
  }
  &__chat-holder {
    background-color: white;
    border-radius: 8px;
    &__header {
      padding: 12px 16px;
      @include from-tablet-sm {
        padding: 12px 24px;
      }
      @include from-tablet-lg {
        padding: 18px 24px;
      }
      @include from-desktop-lg {
        padding: 35px 24px;
      }
      button {
        img {
          width: 20px;
        }
      }
      &__time-counter {
        background-color: $primary-6;
        padding: 4px 16px;
        border-radius: 25px;
        &--green {
          color: $color4;
        }
        &--yellow {
          color: $auction--yellow;
        }
        &--red {
          color: $request--rejected;
        }
      }
    }
    &__body {
      background-color: $gray-4;
      height: 70svh;
      overflow-y: auto;
      & > div {
        padding: 10px;
        gap: 12px;
      }
      &__empty-msg {
        color: $gray-8;
      }
      &__message {
        img {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          @include from-tablet-sm {
            width: 38px;
            height: 38px;
          }
          @include from-tablet-lg {
            width: 48px;
            height: 48px;
          }
          @include from-desktop-lg {
            width: 60px;
            height: 60px;
          }
        }
        &__holder {
          width: 90%;
          @include from-tablet-sm {
            width: 60%;
          }
          background-color: white;
          padding: 12px;
          border-radius: 12px;
          &--sent-by-current {
            background-color: $primary-6;
          }
        }
      }
    }
    &__footer {
      background-color: white;
      border-radius: 0 0 8px 8px;
      padding: 12px 16px 2px;
      @include from-tablet-sm {
        padding: 12px 24px 2px;
      }
      .ant-input-number {
        border-radius: 4px;
        border-color: $color4;
        @include from-tablet-sm {
          padding-block: 7px;
        }
        @include from-desktop-lg {
          padding-block: 12px;
        }
        &-disabled {
          background-color: $gray-6 !important;
        }
        input {
          height: auto;
          font-weight: 700;
          color: $natural--black;
          &::placeholder {
            color: $gray-8;
            font-weight: 500;
          }
        }
      }
      &__submit-btn {
        &,
        &:disabled {
          background-color: $color4;
        }
        border-radius: 50%;
        padding: 7px;
        width: 32px;
        height: 32px;
        @include from-tablet-sm {
          width: 40px;
          height: 40px;
        }
        @include from-desktop-lg {
          width: 56px;
          height: 56px;
        }
      }
    }
    &__joined-groups {
      background-color: $gray-4;
      z-index: 10;
      transform: translateX(100%);
      transition: transform 0.45s ease;
      &--show {
        transform: translateX(0);
      }
      &__close-btn {
        img {
          width: 20px;
          @include from-tablet-sm {
            width: 28px;
          }
          @include from-desktop-lg {
            width: 40px;
          }
        }
      }
      &__group {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    &__last-minute-counter {
      background-color: rgba(201, 217, 223, 0.7);
      border-radius: 8px;
      width: fit-content;
      z-index: 9;
      top: 84px;
      padding: 30px;
      transition: opacity 0.5s ease;
      opacity: 0;
      &--show {
        opacity: 1;
      }
      @include from-desktop-sm {
        padding: 50px;
      }
      span {
        color: $secondary-8;
      }
      &--red {
        span {
          color: $error;
        }
      }
    }
    &__auction-end-img {
      opacity: 0;
      transition: opacity 0.2s ease;
      &--show {
        opacity: 1;
      }
    }
    &__market-price-alert {
      top: 12px;
      background-color: $secondary-4;
      padding: 4px 12px;
      border-radius: 40px;
      max-width: 90%;
      width: max-content;
      transform: translateY(-200%) translateX(-50%);
      transition: transform 0.3s ease;
      &--show {
        transform: translateY(0) translateX(-50%); 
      }
      img {
        width: 24px;
      }
    }
  }
}
