.invoice {
  &__animation-holder {
    border-radius: 50%;
    background-color: $gray-2;
    width: 51px;
    height: 51px;
    margin-bottom: 11px;
    @include from-tablet-sm {
      width: 107px;
      height: 107px;
      margin-bottom: 22px;
    }
    @include from-desktop-sm {
      width: 150px;
      height: 150px;
      margin-bottom: 32px;
    }
  }
  h1 {
    color: $black-1;
    margin-bottom: 8px;
    @include from-tablet-sm {
      margin-bottom: 19px;
    }
    @include from-desktop-sm {
      margin-bottom: 23px;
    }
  }
  &__data-holder {
    background-color: $gray-3;
    border-radius: 6px;
    margin-bottom: 9px;
    padding: 8px 4px;
    @include from-tablet-sm {
      margin-bottom: 24px;
      border-radius: 11px;
      padding: 8.5px;
    }
    @include from-desktop-sm {
      padding: 12px;
      margin-bottom: 40px;
      border-radius: 16px;
    }
    ul {
      gap: 8px;
      @include from-tablet-sm {
        gap: 9px;
      }
      @include from-desktop-sm {
        gap: 12px;
      }
    }
    &__title {
      color: $gray-9;
    }
    &__value {
      background-color: white;
      border-radius: 14px;
      padding: 0.6px 3px;
      @include from-tablet-sm {
        border-radius: 29px;
        padding: 1.4px 5.7px;
      }
      @include from-desktop-sm {
        padding: 2px 8px;
        border-radius: 41px;
      }
      &,
      &--total-price {
        color: $black-1;
      }
    }
  }
  &__btn {
    border-radius: 6px;
    @include from-tablet-sm {
      border-radius: 11px;
    }
    @include from-desktop-sm {
      border-radius: 16px;
    }
    &--back {
      background-color: $color1 !important;
    }
    &--submit {
      background-color: $green-1;
    }
    &.fsize-5 {
      @include to-mobile-sm {
        font-size: 0.6875rem;
      }
    }
  }
}
