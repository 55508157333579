//-------------------------------------------border colors
.bord-red {
  border: solid 1px red !important;
}
.bord-black {
  border: solid 1px black;
}
.bord-white {
  border: solid 1px white;
}
.bord-blue {
  border: solid 1px blue;
}
.bord-green {
  border: solid 1px green;
}
.bord-orange {
  border: solid 1px orange;
}
.bord-purple {
  border: solid 1px purple;
}
//------------------------------------------------border redius
.radius-circle {
  border-radius: 50%;
}
.radius-0 {
  border-radius: 0px;
}
.radius-1 {
  border-radius: 1px;
}
.radius-2 {
  border-radius: 2px;
}
.radius-3 {
  border-radius: 3px;
}
.radius-4 {
  border-radius: 4px;
}
.radius-5 {
  border-radius: 5px;
}
.radius-6 {
  border-radius: 6px;
}
.radius-7 {
  border-radius: 7px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-9 {
  border-radius: 9px;
}
.radius-10 {
  border-radius: 10px;
}
.radius-11 {
  border-radius: 11px;
}
.radius-12 {
  border-radius: 12px;
}
.radius-13 {
  border-radius: 13px;
}
.radius-14 {
  border-radius: 14px;
}
.radius-15 {
  border-radius: 15px;
}
.radius-16 {
  border-radius: 16px;
}
.radius-17 {
  border-radius: 17px;
}
.radius-18 {
  border-radius: 18px;
}
.radius-19 {
  border-radius: 19px;
}
.radius-20 {
  border-radius: 20px;
}
.radius-21 {
  border-radius: 21px;
}
.radius-22 {
  border-radius: 22px;
}
.radius-23 {
  border-radius: 23px;
}
.radius-24 {
  border-radius: 24px;
}
.radius-25 {
  border-radius: 25px;
}
.radius-50 {
  border-radius: 50px;
}
.radius-75 {
  border-radius: 75px;
}
.radius-100 {
  border-radius: 100px;
}
//--------------------------------------------padding
.pad-0 {
  padding: 0px;
}
.pad-5 {
  padding: 5px;
}
.pad-10 {
  padding: 10px;
}
.pad-15 {
  padding: 15px;
}
.pad-20 {
  padding: 20px;
}
.pad-25 {
  padding: 25px;
}
.pad-30 {
  padding: 30px;
}
.pad-35 {
  padding: 35px;
}
.pad-40 {
  padding: 40px;
}
.pad-45 {
  padding: 45px;
}
.pad-50 {
  padding: 50px;
}
.pad-75 {
  padding: 75px;
}
.pad-100 {
  padding: 100px;
}
//---------------------------padding-inline
.pad-x-0 {
  padding-inline: 0px;
}
.pad-x-5 {
  padding-inline: 5px;
}
.pad-x-6 {
  padding-inline: 6px;
}
.pad-x-7 {
  padding-inline: 7px;
}
.pad-x-8 {
  padding-inline: 8px;
}
.pad-x-9 {
  padding-inline: 9px;
}
.pad-x-10 {
  padding-inline: 10px;
}
.pad-x-15 {
  padding-inline: 15px;
}
.pad-x-20 {
  padding-inline: 20px;
}
.pad-x-25 {
  padding-inline: 25px;
}
.pad-x-30 {
  padding-inline: 30px;
}
.pad-x-35 {
  padding-inline: 35px;
}
.pad-x-40 {
  padding-inline: 40px;
}
.pad-x-45 {
  padding-inline: 45px;
}
.pad-x-50 {
  padding-inline: 50px;
}
.pad-x-75 {
  padding-inline: 75px;
}
.pad-x-100 {
  padding-inline: 100px;
}
//---------------------------padding-inline
.pad-xs-0 {
  padding-inline-start: 0px;
}
.pad-xs-5 {
  padding-inline-start: 5px;
}
.pad-xs-10 {
  padding-inline-start: 10px;
}
.pad-xs-15 {
  padding-inline-start: 15px;
}
.pad-xs-20 {
  padding-inline-start: 20px;
}
.pad-xs-25 {
  padding-inline-start: 25px;
}
.pad-xs-30 {
  padding-inline-start: 30px;
}
.pad-xs-35 {
  padding-inline-start: 35px;
}
.pad-xs-40 {
  padding-inline-start: 40px;
}
.pad-xs-45 {
  padding-inline-start: 45px;
}
.pad-xs-50 {
  padding-inline-start: 50px;
}
.pad-xs-75 {
  padding-inline-start: 75px;
}
.pad-xs-100 {
  padding-inline-start: 100px;
}
//---------------------------padding-inline-end
.pad-xe-0 {
  padding-inline-end: 0px;
}
.pad-xe-5 {
  padding-inline-end: 5px;
}
.pad-xe-10 {
  padding-inline-end: 10px;
}
.pad-xe-15 {
  padding-inline-end: 15px;
}
.pad-xe-20 {
  padding-inline-end: 20px;
}
.pad-xe-25 {
  padding-inline-end: 25px;
}
.pad-xe-30 {
  padding-inline-end: 30px;
}
.pad-xe-35 {
  padding-inline-end: 35px;
}
.pad-xe-40 {
  padding-inline-end: 40px;
}
.pad-xe-45 {
  padding-inline-end: 45px;
}
.pad-xe-50 {
  padding-inline-end: 50px;
}
.pad-xe-75 {
  padding-inline-end: 75px;
}
.pad-xe-100 {
  padding-inline-end: 100px;
}
//---------------------------padding-block
.pad-y-0 {
  padding-block: 0px;
}
.pad-y-5 {
  padding-block: 5px;
}
.pad-y-6 {
  padding-block: 6px;
}
.pad-y-7 {
  padding-block: 7px;
}
.pad-y-8 {
  padding-block: 8px;
}
.pad-y-9 {
  padding-block: 9px;
}
.pad-y-10 {
  padding-block: 10px;
}
.pad-y-15 {
  padding-block: 15px;
}
.pad-y-20 {
  padding-block: 20px;
}
.pad-y-25 {
  padding-block: 25px;
}
.pad-y-30 {
  padding-block: 30px;
}
.pad-y-35 {
  padding-block: 35px;
}
.pad-y-40 {
  padding-block: 40px;
}
.pad-y-45 {
  padding-block: 45px;
}
.pad-y-50 {
  padding-block: 50px;
}
.pad-y-75 {
  padding-block: 75px;
}
.pad-y-100 {
  padding-block: 100px;
}
//---------------------------padding-block-start
.pad-ys-0 {
  padding-block-start: 0px;
}
.pad-ys-5 {
  padding-block-start: 5px;
}
.pad-ys-10 {
  padding-block-start: 10px;
}
.pad-ys-15 {
  padding-block-start: 15px;
}
.pad-ys-20 {
  padding-block-start: 20px;
}
.pad-ys-25 {
  padding-block-start: 25px;
}
.pad-ys-30 {
  padding-block-start: 30px;
}
.pad-ys-35 {
  padding-block-start: 35px;
}
.pad-ys-40 {
  padding-block-start: 40px;
}
.pad-ys-45 {
  padding-block-start: 45px;
}
.pad-ys-50 {
  padding-block-start: 50px;
}
.pad-ys-75 {
  padding-block-start: 75px;
}
.pad-ys-100 {
  padding-block-start: 100px;
}
//---------------------------padding-block-end
.pad-ye-0 {
  padding-block-end: 0px;
}
.pad-ye-5 {
  padding-block-end: 5px;
}
.pad-ye-10 {
  padding-block-end: 10px;
}
.pad-ye-15 {
  padding-block-end: 15px;
}
.pad-ye-20 {
  padding-block-end: 20px;
}
.pad-ye-25 {
  padding-block-end: 25px;
}
.pad-ye-30 {
  padding-block-end: 30px;
}
.pad-ye-35 {
  padding-block-end: 35px;
}
.pad-ye-40 {
  padding-block-end: 40px;
}
.pad-ye-45 {
  padding-block-end: 45px;
}
.pad-ye-50 {
  padding-block-end: 50px;
}
.pad-ye-75 {
  padding-block-end: 75px;
}
.pad-ye-100 {
  padding-block-end: 100px;
}
//----------------------------------------end padding
//----------------------------------------margin
.mrg-auto {
  margin: auto;
}
.mrg-x-auto {
  margin-inline: auto;
}
.mrg-y-auto {
  margin-block: auto;
}
.mrg-0 {
  margin: 0px;
}
.mrg-5 {
  margin: 5px;
}
.mrg-10 {
  margin: 10px;
}
.mrg-15 {
  margin: 15px;
}
.mrg-20 {
  margin: 20px;
}
.mrg-25 {
  margin: 25px;
}
.mrg-30 {
  margin: 30px;
}
.mrg-35 {
  margin: 35px;
}
.mrg-40 {
  margin: 40px;
}
.mrg-45 {
  margin: 45px;
}
.mrg-50 {
  margin: 50px;
}
.mrg-75 {
  margin: 75px;
}
.mrg-100 {
  margin: 100px;
}

//-----------------------------margin-inline
.mrg-x-0 {
  margin-inline: 0px;
}
.mrg-x-5 {
  margin-inline: 5px;
}
.mrg-x-10 {
  margin-inline: 10px;
}
.mrg-x-15 {
  margin-inline: 15px;
}
.mrg-x-20 {
  margin-inline: 20px;
}
.mrg-x-25 {
  margin-inline: 25px;
}
.mrg-x-30 {
  margin-inline: 30px;
}
.mrg-x-35 {
  margin-inline: 35px;
}
.mrg-x-40 {
  margin-inline: 40px;
}
.mrg-x-45 {
  margin-inline: 45px;
}
.mrg-x-50 {
  margin-inline: 50px;
}
.mrg-x-75 {
  margin-inline: 75px;
}
.mrg-x-100 {
  margin-inline: 100px;
}

//-----------------------------margin-inline-start
.mrg-xs-0 {
  margin-inline-start: 0px;
}
.mrg-xs-5 {
  margin-inline-start: 5px;
}
.mrg-xs-10 {
  margin-inline-start: 10px;
}
.mrg-xs-15 {
  margin-inline-start: 15px;
}
.mrg-xs-20 {
  margin-inline-start: 20px;
}
.mrg-xs-25 {
  margin-inline-start: 25px;
}
.mrg-xs-30 {
  margin-inline-start: 30px;
}
.mrg-xs-35 {
  margin-inline-start: 35px;
}
.mrg-xs-40 {
  margin-inline-start: 40px;
}
.mrg-xs-45 {
  margin-inline-start: 45px;
}
.mrg-xs-50 {
  margin-inline-start: 50px;
}
.mrg-xs-75 {
  margin-inline-start: 75px;
}
.mrg-xs-100 {
  margin-inline-start: 100px;
}
//-----------------------------margin-inline-end
.mrg-xe-0 {
  margin-inline-end: 0px;
}
.mrg-xe-5 {
  margin-inline-end: 5px;
}
.mrg-xe-10 {
  margin-inline-end: 10px;
}
.mrg-xe-15 {
  margin-inline-end: 15px;
}
.mrg-xe-20 {
  margin-inline-end: 20px;
}
.mrg-xe-25 {
  margin-inline-end: 25px;
}
.mrg-xe-30 {
  margin-inline-end: 30px;
}
.mrg-xe-35 {
  margin-inline-end: 35px;
}
.mrg-xe-40 {
  margin-inline-end: 40px;
}
.mrg-xe-45 {
  margin-inline-end: 45px;
}
.mrg-xe-50 {
  margin-inline-end: 50px;
}
.mrg-xe-75 {
  margin-inline-end: 75px;
}
.mrg-xe-100 {
  margin-inline-end: 100px;
}
//--------------------------------------------margin-block
.mrg-y-0 {
  margin-block: 0px;
}
.mrg-y-5 {
  margin-block: 5px;
}
.mrg-y-10 {
  margin-block: 10px;
}
.mrg-y-15 {
  margin-block: 15px;
}
.mrg-y-20 {
  margin-block: 20px;
}
.mrg-y-25 {
  margin-block: 25px;
}
.mrg-y-30 {
  margin-block: 30px;
}
.mrg-y-35 {
  margin-block: 35px;
}
.mrg-y-40 {
  margin-block: 40px;
}
.mrg-y-45 {
  margin-block: 45px;
}
.mrg-y-50 {
  margin-block: 50px;
}
.mrg-y-75 {
  margin-block: 75px;
}
.mrg-y-100 {
  margin-block: 100px;
}
//--------------------------------------------margin-block-start
.mrg-ys-0 {
  margin-block-start: 0px;
}
.mrg-ys-5 {
  margin-block-start: 5px;
}
.mrg-ys-10 {
  margin-block-start: 10px;
}
.mrg-ys-15 {
  margin-block-start: 15px;
}
.mrg-ys-20 {
  margin-block-start: 20px;
}
.mrg-ys-25 {
  margin-block-start: 25px;
}
.mrg-ys-30 {
  margin-block-start: 30px;
}
.mrg-ys-35 {
  margin-block-start: 35px;
}
.mrg-ys-40 {
  margin-block-start: 40px;
}
.mrg-ys-45 {
  margin-block-start: 45px;
}
.mrg-ys-50 {
  margin-block-start: 50px;
}
.mrg-ys-75 {
  margin-block-start: 75px;
}
.mrg-ys-100 {
  margin-block-start: 100px;
}
//--------------------------------------------margin-block-end
.mrg-ye-0 {
  margin-block-end: 0px;
}
.mrg-ye-5 {
  margin-block-end: 5px;
}
.mrg-ye-10 {
  margin-block-end: 10px;
}
.mrg-ye-15 {
  margin-block-end: 15px;
}
.mrg-ye-20 {
  margin-block-end: 20px;
}
.mrg-ye-25 {
  margin-block-end: 25px;
}
.mrg-ye-30 {
  margin-block-end: 30px;
}
.mrg-ye-35 {
  margin-block-end: 35px;
}
.mrg-ye-40 {
  margin-block-end: 40px;
}
.mrg-ye-45 {
  margin-block-end: 45px;
}
.mrg-ye-50 {
  margin-block-end: 50px;
}
.mrg-ye-75 {
  margin-block-end: 75px;
}
.mrg-ye-100 {
  margin-block-end: 100px;
}
//--------------------------------------------end margin

//--------------------------------------------display
.d-none {
  display: none;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1 !important;
}

//--------------------------------------------justify-content
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-baseline {
  justify-content: baseline;
}
.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-stretch {
  justify-content: stretch;
}
//------------------------------------------------align-items
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-around {
  align-items: space-around;
}
.items-between {
  align-items: space-between;
}
.items-evenly {
  align-items: space-evenly;
}
.items-stretch {
  align-items: stretch;
}
//---------------------------------------centering
.centering {
  justify-content: center;
  align-items: center;
}
//------------------------------------align-content

.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-center {
  align-content: center;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-evenly {
  align-content: space-evenly;
}
.content-stretch {
  align-content: stretch;
}

//----------------------------flex-direction
.dir-row {
  flex-direction: row;
}
.dir-row-rev {
  flex-direction: row-reverse;
}
.dir-col {
  flex-direction: column;
}
.dir-col-rev {
  flex-direction: column-reverse;
}
//--------------------------------------flex-wrap
.wrap {
  flex-wrap: wrap;
}
.no-wrap {
  flex-wrap: nowrap;
}
.wrap-rev {
  flex-wrap: wrap-reverse;
}
//---------------------------------------flex-flow
.row-wrap {
  flex-flow: row wrap;
}
.row-rev-wrap {
  flex-flow: row-reverse wrap;
}
.row-rev-wrap-rev {
  flex-flow: row-reverse wrap-reverse;
}
.col-wrap-rev {
  flex-flow: column wrap-reverse;
}
.col-rev-wrap-rev {
  flex-flow: column-reverse wrap-reverse;
}
//-------------------------------------------flex-grow

//-------------------------------------------font-size

.fs-5 {
  font-size: 5px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}
.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}
.fs-40 {
  font-size: 40px;
}
.fs-45 {
  font-size: 45px;
}
.fs-50 {
  font-size: 50px;
}
.fs-75 {
  font-size: 75px;
}
.fs-100 {
  font-size: 100px;
}
//-------------------------------------------font-weight
.fw-sm {
  font-weight: small;
}
.fw-md {
  font-weight: medium;
}
.fw-lg {
  font-weight: large;
}
.fw-bold {
  font-weight: bold;
}
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
//------------------------------------------------width

.wd-0 {
  width: 0%;
}
.wd-5 {
  width: 5%;
}
.wd-10 {
  width: 10%;
}
.wd-15 {
  width: 15%;
}
.wd-20 {
  width: 20%;
}
.wd-25 {
  width: 25%;
}
.wd-30 {
  width: 30%;
}
.wd-35 {
  width: 35%;
}
.wd-40 {
  width: 40%;
}
.wd-45 {
  width: 45%;
}
.wd-50 {
  width: 50%;
}
.wd-55 {
  width: 55%;
}
.wd-60 {
  width: 60%;
}
.wd-65 {
  width: 65%;
}
.wd-70 {
  width: 70%;
}
.wd-75 {
  width: 75%;
}
.wd-80 {
  width: 80%;
}
.wd-85 {
  width: 85%;
}
.wd-90 {
  width: 90%;
}
.wd-95 {
  width: 95%;
}
.wd-100 {
  width: 100%;
}
.wd-100vh {
  width: 100vh;
}
//------------------------------------------------height

.hg-0 {
  width: 0%;
}
.hg-5 {
  height: 5%;
}
.hg-10 {
  height: 10%;
}
.hg-15 {
  height: 15%;
}
.hg-20 {
  height: 20%;
}
.hg-25 {
  height: 25%;
}
.hg-30 {
  height: 30%;
}
.hg-35 {
  height: 35%;
}
.hg-40 {
  height: 40%;
}
.hg-45 {
  height: 45%;
}
.hg-50 {
  height: 50%;
}
.hg-55 {
  height: 55%;
}
.hg-60 {
  height: 60%;
}
.hg-65 {
  height: 65%;
}
.wd-70 {
  width: 70%;
}
.hg-75 {
  height: 75%;
}
.hg-80 {
  height: 80%;
}
.hg-85 {
  height: 85%;
}
.hg-90 {
  height: 90%;
}
.hg-95 {
  height: 95%;
}
.hg-100 {
  height: 100%;
}
.hg-25vh {
  height: 25vh;
}
.hg-50vh {
  height: 50vh;
}
.hg-75vh {
  height: 75vh;
}
.hg-100vh {
  height: 100vh;
}
//------------------------------------z-index
.zindx-0 {
  z-index: 0;
}
.zindx-1 {
  z-index: 1;
}
.zindx-5 {
  z-index: 5;
}
.zindx-10 {
  z-index: 10;
}
.zindx-15 {
  z-index: 15;
}
.zindx-20 {
  z-index: 20;
}
.zindx-25 {
  z-index: 25;
}
.zindx-50 {
  z-index: 50;
}
.zindx-75 {
  z-index: 75;
}
.zindx-100 {
  z-index: 100;
}
.zindx-1000 {
  z-index: 1000;
}
//------------------------------------position
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
//-----------------------------overflow
.of-hidden {
  overflow: hidden;
}
.of-scroll {
  overflow: scroll;
}
.of-auto {
  overflow: auto;
}
.ofy-auto {
  overflow-y: auto;
}
.ofy-hidden {
  overflow-y: hidden;
}
.ofy-scroll {
  overflow-y: scroll;
}
.ofx-auto {
  overflow-x: auto;
}
.ofx-hidden {
  overflow-x: hidden;
}
.ofx-scroll {
  overflow-x: scroll;
}