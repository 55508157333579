/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.contact-us {
  width: 100%;
  padding-inline: 50px;
  &__subtitle {
    text-align: left;
    font-size: 25px;
    font-weight: 500;
    color: $main-color;
  }
  &__content {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &__data {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-block: 20px;
  }
  &__form {
    width: 50%;
    padding: 50px;
    border-radius: 16px;
    background-color: $sign-in-bg;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-grid-column-align: center;
    justify-self: center;
    label {
      color: $color6-text;
    }
  }
  &__form-field {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  &__form-lbl {
    font-size: 18px;
    font-weight: 500;
    text-align: start;
    -webkit-margin-before: 15px;
    margin-block-start: 15px;
    -webkit-margin-after: 5px;
    margin-block-end: 5px;
  }
  &__form-input {
    &,
    &:autofill {
      background-color: $color7-nav-text !important;
    }
    &:autofill {
      -webkit-box-shadow: 0 0 0 30px $color7-nav-text inset !important;
      -moz-box-shadow: 0 0 0 30px $color7-nav-text inset !important;
      -o-box-shadow: 0 0 0 30px $color7-nav-text inset !important;
      box-shadow: 0 0 0 30px $color7-nav-text inset !important;
    }
    width: 100% !important;
    padding: 10px 10px 10px 16px;
    border-radius: 5px;
    outline: 0;
  }

  &__form-txtarea {
    width: 100% !important;
    height: 148px;
    outline: 0;
    padding: 20px;
    resize: none;
    border-radius: 5px;
  }
  &__form-txtarea:hover {
    border: solid $main-color 1px;
    background-color: $fifth-color;
  }

  &__field-error {
    color: $red-color;
  }
  &__form-btn {
    width: 13.3125rem;
    text-align: center;
    background-color: $color1;
    color: $color6-text;
    border-radius: 31px;
    &:not[disabled]:hover {
      color: $color6-text !important;
      background-color: $color4;
      border-color: $color4;
    }
  }
  &__form-btn-reject {
    width: 13.3125rem;
    height: 57px;
    margin-block: 30px;
    text-align: center;
    background-color: $fifth-color;
    color: red;
    border: solid red 2px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    &:hover {
      color: red !important;
      border: solid red 3px !important ;
      background-color: $fifth-color;
    }
  }
  &__form-login-btn {
    width: 8.3125rem;
    height: 37px;
    margin-block: 30px;
    text-align: center;
    color: $first-color;
    border: solid $first-color 2px !important ;
    outline: none;
    background-color: $main-color;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    &:hover {
      color: $main-color !important;
      border: solid $main-color 2px !important ;
      background-color: $first-color;
    }
  }
  &__form-logout-btn {
    width: 8.3125rem;
    height: 37px;
    margin-block: 30px;
    text-align: center;
    background-color: $red-color;
    color: $first-color;
    border-color: $first-color !important;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    outline: none !important;
    &:hover {
      border: solid $red-color 3px !important;
      color: $red-color !important;
      background-color: $first-color;
    }
  }
  &__submit {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
  &__social {
    width: 50%;
    padding-inline: 10px;
    padding-block: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  &__call-way {
    width: 100%;
    margin-block: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
  }
  &__phone-number {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: $main-color;
  }
  &__email {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    color: $main-color;
  }
  &__img-figure {
    width: 45.23px;
    height: 45.23px;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-margin-end: 20px;
    margin-inline-end: 20px;
  }
  &__img-fig {
    width: 100%;
    height: 100%;
  }
  &__country-code {
    @include to-tablet-sm {
      width: 41px;
    }
  }
}

@include to-tablet-lg {
  .contact-us {
    padding-inline: 30px;

    &__data {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    &__form {
      width: 80%;
    }
    &__social {
      width: 80%;
      padding: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    &__call-way {
      margin-block: 5px;
    }
  }
}
@include to-tablet-sm {
  .contact-us {
    padding-inline: 30px;
    &__form {
      padding: 40px 20px;
    }
    &__form-field label {
      font-size: 1rem;
    }
  }
}
@include to-mobile-sm {
  .contact-us {
    &__subtitle {
      font-size: 18px;
    }
    &__data {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    &__form {
      width: 90%;
    }
    &__social {
      width: 100%;
    }
    &__phone-number {
      font-size: 16px;
    }
    &__email {
      font-size: 14px;
    }
    &__img-figure {
      -webkit-margin-end: 15px;
      margin-inline-end: 15px;
    }
  }
}
