.profile-drawer {
  &--ar {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-close {
        margin-inline-end: 0;
        margin-inline-start: 12px;
      }
    }
  }
  .ant-drawer-body {
    background-color: $color1;
    color: white;
  }
  &__main-holder {
    gap: 50px;
    @include from-desktop-sm {
      gap: 70px;
    }
    @include from-desktop-lg {
      gap: 100px;
    }
  }
  &__profile-icon {
    width: 112px;
    height: 112px;
    @include to-tablet-lg {
      width: 75px;
      height: 75px;
    }
  }
  &__phone-icon {
    width: 30px;
    height: 30px;
    @include to-tablet-lg {
      width: 24px;
      height: 24px;
    }
  }
  &__empty-groups-msg {
    color: $secondary-8;
  }
  &__logout-btn {
    text-decoration: none;
    span {
      color: $secondary-6;
    }
    img {
      width: 30px;
      height: 30px;
      @include to-tablet-lg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
